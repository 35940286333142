import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { dayjs } from '../../../../lib/dayjs';
import { isNewsTypeColor } from '../../../../lib/is-news-type-color';
import { Layout } from '../../../../components/layout';
import { BreadCrumb } from '../../../../components/breadcrumb';
import { BackGround } from '../../../../components/background';
import { Seo } from '../../../../components/seo';
import { SeasonHeader } from '../../../../components/season-header';
import * as Styles from '../../../../styles/pages/_seasonId.module.scss';
import * as CommonStyles from '../../../../styles/global/_common.module.scss';
import {Link, useIntl, navigate} from "gatsby-plugin-react-intl"
import { contentPathHelper } from '../../../../utils/contentPathHelper';
import { useCheerio } from '../../../../hooks/use-cheerio';

const SeasonSinglePage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const { microcmsSeason, microcmsEvent, allMicrocmsSeason, allMicrocmsMatch, allMicrocmsNews } = data;

  const $ = useCheerio(microcmsSeason?.rule)
  const isRuleText = useMemo(() => !!$.text(), [$])

  React.useEffect(() => {
    if (!microcmsSeason) {
      navigate(`/news/${microcmsEvent.eventId}`)
    }
  }, [microcmsSeason])

  const postDescription = microcmsEvent.description?.replace(/(<([^>]+)>)/gi, '').substr(0, 70);

  const eventLabel = microcmsSeason?.events.filter((event) => {
    return event.events.id === microcmsEvent.eventId;
  });

  // 小数点第何位まで表示するか
  const digit = 1;
  const tables = [];
  allMicrocmsMatch.edges.forEach(({ node }) => {
    if (node.red[0] && !tables.some((artist) => artist.id === node.red[0].id)) {
      tables.push({
        id: String(node.red[0].id),
        name: String(node.red[0].name),
        image: String(node.red[0].image_url || node.red[0].image.url),
        win_point: Number(node.red_point),
        match: 1,
        examination: 0,
        win: 0,
        lose: 0,
        draw: 0,
      });
    } else if (node.red[0] && node.red_point) {
      const index = tables.findIndex((artist) => artist.id === node.red[0].id);
      index >= 0 && tables[index].match++;
      index >= 0 && (tables[index].win_point += Number(node.red_point));
    }

    if (node.blue[0] && !tables.some((artist) => artist.id === node.blue[0].id)) {
      tables.push({
        id: String(node.blue[0].id),
        name: String(node.blue[0].name),
        image: String(node.blue[0].image_url || node.blue[0].image.url),
        win_point: Number(node.red_point),
        match: 1,
        examination: 0,
        win: 0,
        lose: 0,
        draw: 0,
      });
    } else if (node.blue[0] && node.blue_point) {
      const index = tables.findIndex((artist) => artist.id === node.blue[0].id);
      index >= 0 && tables[index].match++;
      index >= 0 && (tables[index].win_point += Number(node.blue_point));
    }

    if (node.red[0] && typeof node.red_examination === 'number') {
      const index = tables.findIndex(({ id }) => id === node.red[0].id);
      index >= 0 && (tables[index].examination += node.red_examination);
    }

    if (node.blue[0] && typeof node.blue_examination === 'number') {
      const index = tables.findIndex(({ id }) => id === node.blue[0].id);
      index >= 0 && (tables[index].examination += node.blue_examination);
    }

    if (!node.winner.length || !node.red[0] || !node.blue[0]) {
      return false;
    }

    switch (node.winner[0]){
      case '赤':
        tables[tables.findIndex(({ id }) => id === node.red[0].id)].win++;
        tables[tables.findIndex(({ id }) => id === node.blue[0].id)].lose++;
        break;

      case '青':
        tables[tables.findIndex(({ id }) => id === node.blue[0].id)].win++;
        tables[tables.findIndex(({ id }) => id === node.red[0].id)].lose++;
        break;

      default:
        tables[tables.findIndex(({ id }) => id === node.red[0].id)].draw++;
        tables[tables.findIndex(({ id }) => id === node.blue[0].id)].draw++;
        break;
    }
  });

  tables.sort((a, b) => {
    if (a.win_point < b.win_point) return -1;
    if (a.win_point > b.win_point) return 1;
    return 0;
  });

  const relatedArticles = () => {
    if (!microcmsSeason.relation.length) {
      return [];
    }

    return allMicrocmsNews.edges.filter(({ node }) => {
      let check = false;

      node.relation.forEach((relation) => {
        microcmsSeason.relation.forEach(({ id }) => {
          if (relation.id === id) {
            check = true;
          }
        });
      });

      return check;
    });
  };

  return (
    <Layout location={location}>
      {microcmsSeason && (
        <>
          <BreadCrumb
            list={[
              {
                title: 'TOP',
                link: '/',
              },
              {
                title: t('menu.seasonInformation')
              },
              {
                title: microcmsSeason?.title,
                link: '/season/' + microcmsSeason?.seasonId + '/',
              },
              {
                title: eventLabel[0].label,
                link: '/season/event/' + microcmsEvent.eventId + '/',
              },
            ]}
            currentPage={t('season.ranking')}
          />
          <Seo title={`${t('season.ranking')} | ${eventLabel[0].label} | ${microcmsSeason.title}${t('season.seo.title')}`} description={postDescription} />
        </>
      )}
      <BackGround>
        {microcmsSeason && <SeasonHeader currentSeason={microcmsSeason} seasonList={allMicrocmsSeason.edges} linkList={microcmsSeason.events} location={location} isSeasonRule={isRuleText} />}
        <section className={Styles.seasonId}>
          <div className={Styles.seasonId__wrapper}>
            <div className={classNames(CommonStyles.container)}>
              <h2 className={Styles.seasonId__header}>{microcmsEvent.title}</h2>

              <div className={Styles.seasonId__thumbnail}>
                <img src={microcmsEvent.image.url + '?w=1660&q=80'} alt={microcmsEvent.title} />
              </div>

              <div
                className={Styles.seasonId__description}
                dangerouslySetInnerHTML={{
                  __html: microcmsEvent.description,
                }}
              />

              <ul id="menu-list" className={Styles.seasonId__menu_list}>
                <li>
                  <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}#menu-list/`}>{t('season.matchSchedule')}</Link>
                </li>
                <li>
                  <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/result#menu-list/`}>{t('season.matchResult')}</Link>
                </li>

                {microcmsEvent.rule && (
                  <li>
                    <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/rule#menu-list/`}>{t('season.rules')}</Link>
                  </li>
                )}

                {allMicrocmsMatch.edges.length !== 0 && microcmsEvent.ranking && (
                  <li>
                    <Link className={Styles.seasonId__menu_list__current} to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/ranking#menu-list/`}>
                      {t('season.ranking')}
                    </Link>
                  </li>
                )}

                {microcmsEvent.tournament && (
                  <li>
                    <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/tournament#menu-list/`}>{t('season.tournament')}</Link>
                  </li>
                )}
              </ul>

              <div className={Styles.seasonId__table_wrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{t('season.result.order')}</th>
                      <th>{t('season.result.artist')}</th>
                      <th>{t('season.result.point')}</th>
                      <th>{t('season.result.average')}</th>
                      <th>{t('season.result.match')}</th>
                      <th>{t('season.result.win')}</th>
                      <th>{t('season.result.lose')}</th>
                      <th>{t('season.result.draw')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tables.map((table, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <img src={`${table.image}?w=34&h=34&fit=crop`} alt={table.name} />

                          <Link to={`/artist/${contentPathHelper(table.id)}/`}>{table.name}</Link>
                        </td>
                        <td>{table.win_point}</td>
                        <td>{(table.examination / table.match).toFixed(digit)}</td>
                        <td>{table.match}</td>
                        <td>{table.win}</td>
                        <td>{table.lose}</td>
                        <td>{table.draw}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <h2 className={Styles.seasonId__accent_heading}>関連記事</h2>

              {microcmsSeason ? (
                <ul className={Styles.seasonId__list}>
                  {relatedArticles()
                    .slice(0, 3)
                    .map(({ node }) => (
                      <li key={node.seasonId} className={Styles.seasonId__list_card}>
                        <Link to={`/news/${contentPathHelper(node.newsId)}/`}>
                          <img src={node.image.url} alt="" />
                          <div className={Styles.seasonId__boxwrap}>
                            <div className={Styles.seasonId__set}>
                              {dayjs(node.publishedAt).format('YYYY/MM/DD')}{' '}
                              <p className={Styles.seasonId__newscategory} style={{ backgroundColor: isNewsTypeColor(node.type[0]) }}>
                                {node.type[0]}
                              </p>
                            </div>
                            <p className={Styles.seasonId__newstitle}>{node.title}</p>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              ) : (
                <p>{t('season.noArticles')}</p>
              )}
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query ($eventId: String) {
    microcmsEvent(eventId: { eq: $eventId }) {
      eventId
      publishedAt
      date
      in_date
      out_date
      title
      image {
        url
      }
      description
      news_relation {
        id
        publishedAt
        revisedAt
        description
      }
      about {
        fieldId
        description
        prize
        win_lose
        ohter_field {
          fieldId
          title
          content
        }
        judge_field {
          fieldId
          image {
            url
          }
          title
          name
        }
      }
      rule
      concretes {
        fieldId
        theme
      }
      abstracts {
        fieldId
        theme
      }
      tournament {
        url
      }
      ranking
    }

    allMicrocmsMatch(filter: { event: { id: { eq: $eventId } } }) {
      edges {
        node {
          matchId
          title
          date
          event {
            id
            title
            type
            news_relation {
              id
            }
          }
          red {
            id
            name
            image_url
            image {
              url
            }
          }
          red_point
          red_examination
          blue {
            id
            name
            image_url
            image {
              url
            }
          }
          blue_point
          blue_examination
          concrete
          abstract
          video_id
          publishedAt
          winner
        }
      }
    }

    microcmsSeason(events: { elemMatch: { events: { id: { eq: $eventId } } } }) {
      seasonId
      title
      publishedAt
      about
      rule
      relation {
        id
      }
      events {
        fieldId
        label
        events {
          date
          description
          id
          abstracts {
            fieldId
            theme
          }
          about {
            fieldId
            judge_field {
              fieldId
              image {
                url
              }
              name
              title
            }
            ohter_field {
              content
              fieldId
              title
            }
            prize
            win_lose
          }
          concretes {
            fieldId
            theme
          }
          image {
            url
          }
          publishedAt
          out_date
          in_date
          news_relation {
            id
          }
          tournament {
            url
          }
          title
          rule
          type
        }
      }
    }

    allMicrocmsSeason(filter: {is_private: {eq: false}}) {
      edges {
        node {
          seasonId
          title
        }
      }
    }

    allMicrocmsNews(sort: { fields: [publishedAt], order: ASC }) {
      edges {
        node {
          newsId
          image {
            url
          }
          title
          publishedAt
          type
          relation {
            id
            description
          }
        }
      }
    }
  }
`;

export default SeasonSinglePage;
